"use strict";

// START Document Ready
(function ($) {
	"use strict";

	$(document).ready(function () {

		// Detect for retina screen
		// https://coderwall.com/p/q2z2uw/detect-hidpi-retina-displays-in-javascript
		function isRetina() {
			var mediaQuery = "(-webkit-min-device-pixel-ratio: 1.5),\
		            (min--moz-device-pixel-ratio: 1.5),\
		            (-o-min-device-pixel-ratio: 3/2),\
		            (min-resolution: 1.5dppx)";
			if (window.devicePixelRatio > 1) return true;
			if (window.matchMedia && window.matchMedia(mediaQuery).matches) return true;
			return false;
		};

		// 
		// 
		// 
		$('section.display').each(function () {
			if ($(this).attr('data-image-src')) {
				var label = '';
				if (isRetina()) {
					if (window.matchMedia('screen and (min-width: 600px)').matches) {
						label = '@2x';
					} else if (window.matchMedia('screen and (min-width: 1800px)').matches) {
						label = '@3x';
					} else if (window.matchMedia('screen and (min-width: 3200px)').matches) {
						label = '@4x';
					}
				} else {
					if (window.matchMedia('screen and (min-width: 1200px)').matches) {
						label = '@2x';
					} else if (window.matchMedia('screen and (min-width: 2400px)').matches) {
						label = '@3x';
					}
				}
				var img = "url(" + $(this).attr('data-image-src') + label + ".jpg)";
				var img = img.replace(/(\.[\w\d_-]+)$/i, 'label');
				$(this).find('figure').css("background-image", img);
			}
		});
		// 
		$('section.display').each(function () {
			if ($(this).attr('data-image-src')) {
				var where = $(this).find('a').attr("href");
				$(this).find('figure').on('click', function () {
					window.open(where);
				});
			}
		});
	});
})(jQuery);